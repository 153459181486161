















































import { Vue, Component, Prop} from 'vue-property-decorator';
import { TrainingForSchema, TrainingsForSchema } from '../../models/trainings-for-scheduler.model';

import { DateHelper } from '@/shared/helpers/date.helper';
import dayjs from 'dayjs';

@Component
export default class TrainingSchedulerDayGroup extends Vue {

    @Prop() data!: TrainingsForSchema;

    get getDate () : string {
        const year = dayjs(this.data.date).format("YYYY");
        const day = dayjs(this.data.date).format("DD");

        const dayLong = dayjs(this.data.date).format("dddd");
        const dayStr = dayLong.charAt(0).toUpperCase() + dayLong.slice(1);

        const month = dayjs(this.data.date).format("MMMM");
        const monthStr = month.charAt(0).toUpperCase() + month.slice(1);

        return `${dayStr} ${day} ${monthStr} ${year}`;
    }

    get tableHeaders () : { key: string, label: string, thStyle: any, tdClass: any }[] {
        return [
            { key: 'team', label: `${this.$t('trainingScheduler.dayGroupTable.teamsHeader')}`, thStyle: { textAlign: "left", padding: "0.5rem", borderTop: "none", width: "20%", fontSize: "14px" }, tdClass: 'text-left border-top pb-3 pt-2 py-sm-2 table-font' },
            { key: 'time', label: `${this.$t('trainingScheduler.dayGroupTable.timeHeader')}`, thStyle: { textAlign: "center", padding: "0.5rem", borderTop: "none", width: "20%", fontSize: "14px" }, tdClass: 'text-center border-top pb-3 pt-2 py-sm-2 ' },
            { key: 'field', label: `${this.$t('trainingScheduler.dayGroupTable.fieldHeader')}`, thStyle: { textAlign: "center", padding: "0.5rem", borderTop: "none", width: "20%", fontSize: "14px" }, tdClass: 'text-center border-top pb-3 pt-2 py-sm-2 text-muted table-font' },
            { key: 'description', label: `${this.$t('trainingScheduler.dayGroupTable.descriptionHeader')}`, thStyle: { textAlign: "center", padding: "0.5rem", borderTop: "none", width: "20%", fontSize: "14px" }, tdClass: 'text-center border-top pb-3 pt-2 py-sm-2 text-muted table-font' },
            { key: 'trainers', label: `${this.$t('trainingScheduler.dayGroupTable.trainersHeader')}`, thStyle: { textAlign: "center", padding: "0.5rem", borderTop: "none", width: "20%", fontSize: "14px" }, tdClass: 'text-center border-top pb-3 pt-2 py-sm-2 table-font' }
        ]
    }

    get tableValues () : TrainingForSchema[] {
        return this.data.trainings;
    }

    getTime(value: string) : string {
        return DateHelper.parseTime(value);
    }
}
