var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "training-scheduler" },
    [
      _c(
        "b-container",
        { staticClass: "bv-example-row" },
        [
          _c(
            "b-row",
            {
              staticStyle: {
                padding: "10px 5px 10px 5px",
                background: "lightgray",
                "border-radius": "6px",
              },
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        "track-by": "value",
                        label: "text",
                        options: _vm.teamsOptions,
                        "open-direction": "bottom",
                        multiple: true,
                        "max-height": 600,
                        "clear-on-select": true,
                        "show-no-results": true,
                        "show-no-options": false,
                        "show-labels": false,
                        "close-on-select": false,
                        placeholder: _vm.$t(
                          "trainingScheduler.select.placeholder"
                        ),
                      },
                      on: { remove: _vm.onSelectionRemove },
                      model: {
                        value: _vm.selectedTeams,
                        callback: function ($$v) {
                          _vm.selectedTeams = $$v
                        },
                        expression: "selectedTeams",
                      },
                    },
                    [
                      _c("template", { slot: "selection" }, [
                        _c("span", { staticClass: "multiselect__single" }, [
                          _vm._v(_vm._s(_vm.selectedLabel)),
                        ]),
                      ]),
                      _c("template", { slot: "noResult" }, [
                        _c("span", { staticClass: "multiselect__single" }, [
                          _vm._v(
                            _vm._s(_vm.$t("trainingScheduler.select.noResults"))
                          ),
                        ]),
                      ]),
                      _c("template", { slot: "noOptions" }, [
                        _c("span", { staticClass: "multiselect__single" }, [
                          _vm._v(
                            _vm._s(_vm.$t("trainingScheduler.select.noOptions"))
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                      border: "1px solid #c5c5c5",
                      "border-radius": "6px",
                      "background-color": "#e9ecef",
                    },
                  },
                  [
                    _c("date-picker", {
                      attrs: {
                        initValue: _vm.selectedDateFrom,
                        "min-date": _vm.seasonStartDate,
                        "max-date": _vm.seasonEndDate,
                      },
                      on: { onChange: _vm.startDateChanged },
                    }),
                    _c("span", { staticClass: "p-1" }, [
                      _vm._v(_vm._s(_vm.$t("trainingScheduler.to"))),
                    ]),
                    _c("date-picker", {
                      attrs: {
                        initValue: _vm.selectedDateTo,
                        "min-date": _vm.seasonStartDate,
                        "max-date": _vm.seasonEndDate,
                      },
                      on: { onChange: _vm.endDateChanged },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                    "align-items": "center",
                  },
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.fetchTrainings },
                    },
                    [_vm._v(_vm._s(_vm.$t("trainingScheduler.filter")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.trainingsLoaded
        ? _c(
            "div",
            {
              staticClass: "loader-container",
              staticStyle: { "text-align": "center" },
            },
            [_c("Loader")],
            1
          )
        : _vm.trainingsLoaded && _vm.data.length === 0
        ? _c(
            "div",
            {
              staticStyle: {
                padding: "15px",
                margin: "15px",
                "text-align": "center",
              },
            },
            [
              _c("span", [
                _c("i", [
                  _vm._v(_vm._s(_vm.$t("trainingScheduler.noTrainingsFound"))),
                ]),
              ]),
            ]
          )
        : _c(
            "div",
            _vm._l(_vm.data, function (day) {
              return _c("training-day", {
                key: "" + day.date,
                attrs: { data: day },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }