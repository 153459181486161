






















































import { inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { identifier, ITrainingScheduleApiService } from '../services/trainingScheduler.service';

import TrainingSchedulerDayGroup from './components/TrainingSchedulerDayGroup.vue';
import { TrainingsForSchema } from '../models/trainings-for-scheduler.model';
import { SelectListItem } from '@/ui/models/select-list-item.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import dayjs from 'dayjs';

@Component({
    components: {
        'training-day': TrainingSchedulerDayGroup
    }
})
export default class TrainingScheduler extends Vue {

    trainingsLoaded = false;
    data: TrainingsForSchema[] = [];

    
    teamsOptions: SelectListItem[] = [];
    selectedTeams: SelectListItem[] = [];

    seasonStartDate: string | null = null;
    seasonEndDate: string | null = null;

    selectedDateFrom: string | null = null;
    selectedDateTo: string | null = null;

    defaultSelect!: boolean;

    @inject(identifier)
    private trainingSchedulerApiService!: ITrainingScheduleApiService

    @Getter
    private clubId!: string;

    async created(): Promise<void> {
        await this.fetchFiltersData();
        await this.fetchTrainings();
    }

    async fetchFiltersData(): Promise<void> {
        try {
            const response = await this.trainingSchedulerApiService.getTeamsAndDates(this.clubId);

            this.seasonStartDate = dayjs(response.seasonStartDate).format('YYYY-MM-DD');
            this.seasonEndDate = dayjs(response.seasonEndDate).format('YYYY-MM-DD');

            this.teamsOptions = response.teams;
            this.selectedTeams = response.teams;
            this.defaultSelect = true;

            this.selectedDateFrom = dayjs().startOf('week').format('YYYY-MM-DD');
            this.selectedDateTo = dayjs().endOf('week').format('YYYY-MM-DD');
        }
        catch (e) {
            ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
        }
    }

    async fetchTrainings(): Promise<void> {
      try {
        this.trainingsLoaded = false;

        const result = await this.trainingSchedulerApiService.getTrainings(
          this.clubId,
          this.selectedDateFrom,
          this.selectedDateTo,
          this.selectedTeams.map(x => x.value));
        
        const dayGroups = result.trainings
            .reduce((groups, item) => {
                const key = dayjs(item.startUtc).startOf('day').format('YYYYMMDD');
                
                if (!(groups as any[string])[key]) {
                    (groups as any[string])[key] = [];
                }
                (groups as any[string])[key].push(item);
                return groups;
            }, {});
        
        const trainingsByDay = Object.keys(dayGroups).map((key) => ({ key: dayjs(key).format('YYYYMMDD'), date: dayjs(key).format('YYYY-MM-DD'), trainings: (dayGroups as any[string])[key] }));

        this.data = trainingsByDay;

        this.selectedDateFrom = dayjs(result.dateFrom).format('YYYY-MM-DD');
        this.selectedDateTo = dayjs(result.dateTo).format('YYYY-MM-DD');
      }
      catch (e) {            
          ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
      }
      finally {
          this.trainingsLoaded = true;
      }
    }
    

    startDateChanged (val: string): void {
        this.selectedDateFrom = val;
    }
    
    endDateChanged (val: string): void {
        this.selectedDateTo = val;
    }

    get selectedLabel () : string {
      if (this.selectedTeams.length === 0) {
          return `${this.$t('trainingScheduler.noTeamsSelected')}`;
      }

      if (this.selectedTeams.length === this.teamsOptions.length) {
          return `${this.$t('trainingScheduler.allTeamsSelected')}`;
      }

      return `${this.selectedTeams.length} ${this.$t('trainingScheduler.teamsSelected')}`;
    }

    onSelectionRemove(item: SelectListItem, id: any): void {
        if (this.defaultSelect) {
            Vue.nextTick(() => {
                this.defaultSelect = false;
                this.selectedTeams = [];
                this.selectedTeams.push(item);
            });
        }
    }
} 
