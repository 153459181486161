var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "training-schedule-day" }, [
    _c("div", { staticClass: "section-header mb-3 mt-5" }, [
      _vm._v(" " + _vm._s(_vm.getDate) + " "),
    ]),
    _c(
      "div",
      [
        _c("b-table", {
          staticClass: "details-table table-hover bg-white border",
          attrs: { items: _vm.tableValues, fields: _vm.tableHeaders },
          scopedSlots: _vm._u([
            {
              key: "cell(team)",
              fn: function (row) {
                return [
                  row.item.blockingName
                    ? [_vm._v(_vm._s(row.item.blockingName))]
                    : _vm._l(row.item.teams, function (item, index) {
                        return _c("span", { key: "team_" + index }, [
                          _vm._v(" " + _vm._s(item)),
                          index < row.item.teams.length ? _c("br") : _vm._e(),
                        ])
                      }),
                ]
              },
            },
            {
              key: "cell(time)",
              fn: function (row) {
                return [
                  _c("b-badge", { attrs: { pill: "", variant: "light" } }, [
                    _vm._v(
                      _vm._s(_vm.getTime(row.item.startUtc)) +
                        " - " +
                        _vm._s(_vm.getTime(row.item.endUtc))
                    ),
                  ]),
                ]
              },
            },
            {
              key: "cell(field)",
              fn: function (row) {
                return [
                  _vm._v(" " + _vm._s(row.item.field) + " "),
                  row.item.locationOccupation
                    ? [_vm._v("(" + _vm._s(row.item.locationOccupation) + ")")]
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(description)",
              fn: function (row) {
                return [_vm._v(" " + _vm._s(row.item.description) + " ")]
              },
            },
            {
              key: "cell(trainers)",
              fn: function (row) {
                return _vm._l(row.item.trainers, function (item, index) {
                  return _c("span", { key: "traininer_" + index }, [
                    _vm._v(" " + _vm._s(item)),
                    index < row.item.trainers.length ? _c("br") : _vm._e(),
                  ])
                })
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }